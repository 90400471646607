<template>
  <b-container fluid class="reports-performance-active-passive-income-area-chart">
    <line-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'component-cashflow-quadrant-areachart-period'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </line-chart>
  </b-container>
</template>

<script>
import LineChart from '@/components/chartjs/LineChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'
import SpirectaReportChartMixin from '@/views/reports/_inc/SpirectaReportChartMixin'

export default {
  name: 'ActivePassiveReportV2AreaChart',
  components: { LineChart },
  mixins: [chartOptions, SpirectaReportChartMixin, defaultChartColorPalettes],
  props: {
    isLoaded: { type: Boolean, default: false },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    resolution: { type: String, default: 'month' },
    chartType: { type: String, default: 'income-percentage-area-chart' },
    apiData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.performance.active_passive_v2.table.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }

      // Preparation
      const labels = []
      const aIncomeActiveData = []
      const aExpenseActiveData = []
      const aIncomePassiveData = []
      const aExpensePassiveData = []
      const aIncomeColors = this.getGreenTwoColors()
      const aExpenseColors = this.getRedTwoColors()
      let aDatasets = []
      let sPeriod = null

      this.chartOptions.scales.yAxes[0].stacked = true
      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return value + ' %'
      }

      for (const idx in this.apiData.meta.periods) {
        sPeriod = this.apiData.meta.periods[idx]

        labels.push(this.translatePeriodColumnTitleIntoNiceName(sPeriod, this.resolution))

        aIncomeActiveData.push(this.apiData.income.active.period_percentages[sPeriod].toFixed(0))
        aExpenseActiveData.push((this.apiData.expense.active.period_percentages[sPeriod]).toFixed(0))
        aIncomePassiveData.push(this.apiData.income.passive.period_percentages[sPeriod].toFixed(0))
        aExpensePassiveData.push((this.apiData.expense.passive.period_percentages[sPeriod]).toFixed(0))
      }

      if (this.chartType === 'income-area-percentage-chart') {
        aDatasets = [
          { label: this.$t(this.translationPath + 'income_active'), data: aIncomeActiveData, backgroundColor: aIncomeColors[0], lineWidth: { top: 1 }, borderColor: '#FFFFFF' },
          { label: this.$t(this.translationPath + 'income_passive'), data: aIncomePassiveData, backgroundColor: aIncomeColors[1] }
        ]
      } else if (this.chartType === 'expense-area-percentage-chart') {
        aDatasets = [
          { label: this.$t(this.translationPath + 'expense_active'), data: aExpenseActiveData, backgroundColor: aExpenseColors[0], lineWidth: { top: 1 }, borderColor: '#FFFFFF' },
          { label: this.$t(this.translationPath + 'expense_passive'), data: aExpensePassiveData, backgroundColor: aExpenseColors[1] }
        ]
      }

      this.chartData = {
        labels: labels,
        datasets: aDatasets
      }
    }
  },
  watch: {
    apiData () {
      this.fillChart()
    },
    chartType () {
      this.fillChart()
    }
  },
  created () {
    this.fillChart()
  }
}
</script>
