<template>
  <div class="component-reports-performance-active-passive-report">
    <!-- START GRADIENT HEADER -->
    <b-container class="main-gradient light" fluid>

      <!-- START BREADCRUMB, TITLE, TABS WRAPPER -->
      <b-container class="main-gradient-header-wrapper pb-5">

        <b-row class="breadcrumb-wrapper" no-gutters>
          <b-col no-gutters cols="12">
            <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
          </b-col>
          <b-col cols="12">
            <b-col cols="12" sm="4" md="6" lg="7" xl="8" class="pull-left pl-0 pr-0 pr-md-2">
              <h1 class="text-left">{{ $t(translationPath + 'title') }}
                <b-badge v-if="bIsFilterApplied" class="h1badge ml-2" variant="warning">{{$t('reports._common.badge_filter_active')}} <b-link href="#" class="text-black" @click.stop="clearFilter">x</b-link></b-badge>
              </h1>
            </b-col>
            <b-col cols="12" sm="8" md="6" lg="5" xl="4" class="pull-left pr-0 pl-0 pl-md-2">
              <period-selector
                default-period-type="rolling-3-months"
                :show-resolution="true"
                :default-resolution="resolution"
                :show-period-option-full-period="true"
                :allowed-resolutions="['month', 'year']"
                @period-selected="onPeriodChange"
              />
            </b-col>
          </b-col>
        </b-row>

        <b-row class="main-gradient-tab-menu-wrapper mb-2" no-gutters>
          <b-col no-gutters>
            <active-passive-report-v2-submenu :active="activeTab" @tab-switched="onTabSwitched" />
          </b-col>
        </b-row>

      </b-container>
      <!-- END BREADCRUMB, TITLE, TABS WRAPPER -->

      <!-- START CHART WRAPPER -->
      <b-row class="main-gradient-chart-wrapper" :class="{'d-none': activeTab === 'filter' || activeTab === 'transactions'}">
        <!-- Summary tab has different height -->
        <div class="pb-5" style="width:100%;height:500px;">

          <active-passive-report-v2-income-expense-bar-chart
            v-if="activeTab === 'summary' && ( sSummaryChartToDisplay === 'income-expense-bar-amount-chart' || sSummaryChartToDisplay === 'income-expense-bar-percentage-chart' )"
            :is-loaded="!busyState"
            :api-data="apiData"
            :resolution="resolution"
            :chart-type="sSummaryChartToDisplay"
            :start-date="startDate"
            :end-date="endDate"
          />

          <active-passive-report-v2-area-chart
            v-if="activeTab === 'summary' && ( sSummaryChartToDisplay === 'income-area-percentage-chart' || sSummaryChartToDisplay === 'expense-area-percentage-chart' )"
            :is-loaded="!busyState"
            :api-data="apiData"
            :resolution="resolution"
            :chart-type="sSummaryChartToDisplay"
            :start-date="startDate"
            :end-date="endDate"
          />

          <active-passive-report-v2-account-bar-chart
            v-if="activeTab === 'income' && sIncomeAccountChartToDisplay !== 'income-group-accounts-pie-chart'"
            :is-loaded="!busyState"
            :api-data="apiData"
            :resolution="resolution"
            :chart-type="sIncomeAccountChartToDisplay"
            :start-date="startDate"
            :end-date="endDate"
          />

          <active-passive-report-v2-account-pie-chart
            v-if="activeTab === 'income' && sIncomeAccountChartToDisplay === 'income-group-accounts-pie-chart'"
            :is-loaded="!busyState"
            :api-data="apiData"
            :resolution="resolution"
            :chart-type="sIncomeAccountChartToDisplay"
            :start-date="startDate"
            :end-date="endDate"
          />

          <active-passive-report-v2-account-bar-chart
            v-if="activeTab === 'expense' && sExpenseAccountChartToDisplay !== 'expense-group-accounts-pie-chart'"
            :is-loaded="!busyState"
            :api-data="apiData"
            :resolution="resolution"
            :chart-type="sExpenseAccountChartToDisplay"
            :start-date="startDate"
            :end-date="endDate"
          />

          <active-passive-report-v2-account-pie-chart
            v-if="activeTab === 'expense' && sExpenseAccountChartToDisplay === 'expense-group-accounts-pie-chart'"
            :is-loaded="!busyState"
            :api-data="apiData"
            :resolution="resolution"
            :chart-type="sExpenseAccountChartToDisplay"
            :start-date="startDate"
            :end-date="endDate"
          />

        </div>
      </b-row>
      <!-- END CHART WRAPPER -->

    </b-container>
    <!-- END GRADIENT HEADER -->

    <!-- START PAGE WRAPPER -->
    <b-container class="main-content-wrapper pt-0 transaction-view-wrapper">
      <!-- Table components here -->
      <active-passive-report-v2-total-table
        v-if="activeTab === 'summary'"
        :is-loaded="!busyState"
        :api-data="apiData"
        :resolution="resolution"
        :start-date="startDate"
        :active-chart="sSummaryChartToDisplay"
        :end-date="endDate"
        @chart-to-display="(sEmittedChartId) => sSummaryChartToDisplay = sEmittedChartId"
      />

      <active-passive-report-v2-accounts-table
        v-if="activeTab === 'income' || activeTab === 'expense'"
        :is-loaded="!busyState"
        :api-data="apiData"
        :resolution="resolution"
        :start-date="startDate"
        :active-chart-income="sIncomeAccountChartToDisplay"
        :active-chart-expense="sExpenseAccountChartToDisplay"
        :account-type="activeTab"
        :end-date="endDate"
        @chart-to-display-income="(sEmittedChartId) => sIncomeAccountChartToDisplay = sEmittedChartId"
        @chart-to-display-expense="(sEmittedChartId) => sExpenseAccountChartToDisplay = sEmittedChartId"
      />

      <active-passive-report-v2-transactions-table
        v-if="activeTab === 'transactions'"
        :is-loaded="!busyState"
        :api-data="apiData"
        :resolution="resolution"
        :start-date="startDate"
        :end-date="endDate"
      />

      <report-filter
        v-if="activeTab === 'filter'"
        ref="ReportFilter"
        :filter-data="reportFilterData"
        :show-toast-on-filter-apply="false"
        :disabled="[]"
        @filter-applied="onFilterApplied"
        @filter-data-updated="mergeFilterPartials"
        @current-filter-deleted="onFilterDeleted"
      />

    </b-container>
    <!-- END PAGE WRAPPER -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import titleMixins from '@/mixins/title'
import PeriodSelector from '@/components/common/PeriodSelector'
import ReportFilter from '@/components/common/ReportFilter.vue'
import SpirectaReportMixin from '@/views/reports/_inc/SpirectaReportMixin'
import axios from 'axios'
import ActivePassiveReportV2Submenu from './inc/ActivePassiveReportV2Submenu'
import ActivePassiveReportV2TotalTable from './tables/ActivePassiveReportV2TotalTable'
import ActivePassiveReportV2IncomeExpenseBarChart from './charts/ActivePassiveReportV2IncomeExpenseBarChart'
import ActivePassiveReportV2AreaChart from './charts/ActivePassiveReportV2AreaChart'
import ActivePassiveReportV2AccountsTable from './tables/ActivePassiveReportV2AccountsTable'
import ActivePassiveReportV2AccountBarChart from './charts/ActivePassiveReportV2AccountBarChart'
import ActivePassiveReportV2TransactionsTable from './tables/ActivePassiveReportV2TransactionsTable'
import ActivePassiveReportV2AccountPieChart from './charts/ActivePassiveReportV2AccountPieChart'

export default {
  name: 'ActivePassiveReportV2',
  mixins: [titleMixins, SpirectaReportMixin],
  components: {
    PeriodSelector,
    ReportFilter,
    ActivePassiveReportV2Submenu,
    ActivePassiveReportV2TotalTable,
    ActivePassiveReportV2IncomeExpenseBarChart,
    ActivePassiveReportV2AreaChart,
    ActivePassiveReportV2AccountBarChart,
    ActivePassiveReportV2AccountsTable,
    ActivePassiveReportV2TransactionsTable,
    ActivePassiveReportV2AccountPieChart
  },
  data () {
    return {
      validRouteParams: ['summary', 'income', 'expense', 'transactions', 'filter'],
      activeTab: 'summary',
      apiData: null,
      bHasAPIDataBeenFetchedOnce: false,
      translationPath: 'reports.performance.active_passive_v2.',
      busyState: true,
      startDate: null,
      endDate: null,
      resolution: 'month',
      sSummaryChartToDisplay: 'income-expense-bar-amount-chart',
      sIncomeAccountChartToDisplay: 'income-group-accounts',
      sExpenseAccountChartToDisplay: 'expense-group-accounts',
      reportFilterData: null,
      bIsFilterApplied: false
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    breadcrumbItems () {
      let aBreadcrumb = []

      aBreadcrumb = [
        { text: this.$t('reports._common.reports_and_insights'), to: '/reports/performance' },
        { text: this.$t('reports._common.performance_reports'), to: '/reports/performance/' },
        { text: '', active: true }
      ]

      return aBreadcrumb
    },
    titleMeta () {
      return this.$t(this.translationPath + 'title')
    }
  },
  methods: {
    async loadData () {
      return new Promise((resolve, reject) => {
        this.busyState = true

        let sApiEndpoint = `${process.env.VUE_APP_ROOT_API}/reports/performance/active-passive?start_date=${this.startDate}&end_date=${this.endDate}&resolution=${this.resolution}`

        sApiEndpoint += this.buildFilterQueryStringForApiEndpoint(this.reportFilterData)

        axios.get(sApiEndpoint)
          .then(response => {
            this.apiData = response.data.data
            this.busyState = false
            this.bHasAPIDataBeenFetchedOnce = true
            resolve(response.data.data)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    }
  },
  mounted () {
    if (this.validRouteParams.indexOf(this.$route.params.tab) !== -1) {
      this.activeTab = this.$route.params.tab
    } else {
      this.$router.push({ params: { tab: 'summary' } })
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-reports-performance-active-passive-report {
  width: 100%;
}
</style>
