<template>
  <div class="component-performance-report-active-passive-account-table">
    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>

          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col cols="8">
                  <h2>{{ $t(translationPath + 'title_accounts_' + accountType, { start_date: startDate, end_date: endDate }) }}</h2>
                </b-col>
                <b-col>
                  <b-dropdown right
                              :text="$t(translationPath + 'toggle_chart') + ' | '+ $t('reports.performance.active_passive_v2.charts.'+ (accountType === 'income' ? activeChartIncome : activeChartExpense ))"
                              variant='outline-secondary'
                              class="float-right text-regular toggleColumnWrapper"
                              size="sm"
                              v-if="isLoaded"
                  >
                    <b-dropdown-item v-if="accountType === 'income'" @click="toggleChart('income-group-accounts')" v-bind:class="{ active: activeChartIncome === 'income-group-accounts'}">{{$t('reports.performance.active_passive_v2.charts.income-group-accounts')}}</b-dropdown-item>
                    <b-dropdown-item v-if="accountType === 'income'" @click="toggleChart('income-accounts')" v-bind:class="{ active: activeChartIncome === 'income-accounts'}">{{$t('reports.performance.active_passive_v2.charts.income-accounts')}}</b-dropdown-item>
                    <b-dropdown-item v-if="accountType === 'income'" @click="toggleChart('income-group-accounts-pie-chart')" v-bind:class="{ active: activeChartIncome === 'income-group-accounts-pie-chart'}">{{$t('reports.performance.active_passive_v2.charts.income-group-accounts-pie-chart')}}</b-dropdown-item>
                    <b-dropdown-item v-if="accountType === 'expense'" @click="toggleChart('expense-group-accounts')" v-bind:class="{ active: activeChartExpense === 'expense-group-accounts'}">{{$t('reports.performance.active_passive_v2.charts.expense-group-accounts')}}</b-dropdown-item>
                    <b-dropdown-item v-if="accountType === 'expense'" @click="toggleChart('expense-accounts')" v-bind:class="{ active: activeChartExpense === 'expense-accounts'}">{{$t('reports.performance.active_passive_v2.charts.expense-accounts')}}</b-dropdown-item>
                    <b-dropdown-item v-if="accountType === 'expense'" @click="toggleChart('expense-group-accounts-pie-chart')" v-bind:class="{ active: activeChartExpense === 'expense-group-accounts-pie-chart'}">{{$t('reports.performance.active_passive_v2.charts.expense-group-accounts-pie-chart')}}</b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </template>
            <div class="col-lg-8 pl-0">
              <div v-html="$t(translationPath + 'description_accounts_' + accountType, { start_date: startDate, end_date: endDate })"></div>
            </div>

            <b-table
              v-if="isLoaded"
              class="spirecta-simple-table mb-0"
              show-empty
              hover
              responsive
              striped
              stacked="md"
              :items="this.getTableDataRows()"
              :fields="this.getTableDataColumns()"
              :filter="sTableFilter"
              :filter-included-fields="['title']"
              :busy="!isLoaded"
              :tbodyTrClass="trClass"
            >
              <template v-slot:table-busy>
                <loader/>
              </template>

              <!-- FILTER -->
              <template slot="top-row">
                <td :colspan="this.tableColumns.length">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-search text-secondary"></i>
                    <b-form-input v-model="sTableFilter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
                  </div>
                </td>
              </template>

              <!-- START CUSTOM SLOTS -->

              <!-- slot: amount_active --->
              <template v-slot:cell(amount_active)="row">
                <template v-if="row.item.row_type === 'account' || row.item.row_type === 'subtotal' || row.item.row_type === 'total'">
                  <span class="text-nowrap">{{row.value}}</span>
                </template>
              </template>

              <!-- slot: amount_passive --->
              <template v-slot:cell(amount_passive)="row">
                <template v-if="row.item.row_type === 'account' || row.item.row_type === 'subtotal' || row.item.row_type === 'total'">
                  <span class="text-nowrap">{{row.value}}</span>
                </template>
              </template>

              <!-- slot: amount_total --->
              <template v-slot:cell(amount_total)="row">
                <template v-if="row.item.row_type === 'account' || row.item.row_type === 'subtotal' || row.item.row_type === 'total'">
                  <span class="text-nowrap">{{row.value}}</span>
                </template>
              </template>

              <!-- slot: percentage_active --->
              <template v-slot:cell(percentage_active)="row">
                <template v-if="row.item.row_type === 'account' || row.item.row_type === 'subtotal' || row.item.row_type === 'total'">
                  <span class="text-nowrap">{{row.value}}</span>
                </template>
              </template>

              <!-- slot: percentage_passive --->
              <template v-slot:cell(percentage_passive)="row">
                <template v-if="row.item.row_type === 'account' || row.item.row_type === 'subtotal' || row.item.row_type === 'total'">
                  <span class="text-nowrap">{{row.value}}</span>
                </template>
              </template>

              <!-- END CUSTOM SLOTS -->
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SpirectaReportTableMixin from '@/views/reports/_inc/SpirectaReportTableMixin'
import Loader from '@/components/common/Loader'
import { mapState } from 'vuex'

export default {
  name: 'ActivePassiveReportV2AccountsTable',
  components: { Loader },
  mixins: [SpirectaReportTableMixin],
  props: {
    isLoaded: { type: Boolean, default: false },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    resolution: { type: String, default: 'month' },
    accountType: { type: String, default: 'income' },
    activeChartIncome: { type: String, default: 'income-group-accounts' },
    activeChartExpense: { type: String, default: 'expense-group-accounts' },
    apiData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      translationPath: 'reports.performance.active_passive_v2.table.',
      tableData: [],
      tableColumns: [],
      showChart: 'income-group-accounts',
      sTableFilter: ''
    }
  },
  computed: {
    ...mapState('user', ['currentCOA'])
  },
  methods: {
    getTableDataRows () {
      if (!this.isLoaded) { return [] }

      return this.buildTableAmountDataRows()
    },
    getTableDataColumns () {
      return this.buildTableColumns()
    },
    toggleChart (showChart) {
      this.showChart = showChart
      this.$emit('chart-to-display-' + this.accountType, showChart)
    },
    buildTableColumns () {
      if (!this.isLoaded) { return [] }

      const aColumns = [{ key: 'title', label: '', sortable: false, class: 'text-left' }]

      aColumns.push({ key: 'amount_active', label: this.$t(this.translationPath + 'amount_active'), sortable: false, class: 'text-right ', formatter: this.amountFormatter })
      aColumns.push({ key: 'amount_passive', label: this.$t(this.translationPath + 'amount_passive'), sortable: false, class: 'text-right ', formatter: this.amountFormatter })

      aColumns.push({ key: 'percentage_active', label: this.$t(this.translationPath + 'percentage_active'), sortable: false, class: 'text-right td-col-percentage_active', formatter: this.percentageFormatter })
      aColumns.push({ key: 'percentage_passive', label: this.$t(this.translationPath + 'percentage_passive'), sortable: false, class: 'text-right', formatter: this.percentageFormatter })

      aColumns.push({ key: 'amount_total', label: this.$t('common.total'), sortable: false, class: 'text-right td-col-total', formatter: this.amountFormatter })

      return aColumns
    },
    buildTableAmountDataRows () {
      if (!this.isLoaded) { return [] }
      const rows = []

      const iMultiplier = this.accountType === 'income' ? 1 : -1

      for (const iGroupId in this.apiData.account_groups[this.accountType]) {
        const oTmpGroupAccount = this.apiData.account_groups[this.accountType][iGroupId]
        rows.push({ title: oTmpGroupAccount.label, amount: 0, row_type: 'subheading' })

        for (const iAccountId in oTmpGroupAccount.accounts) {
          const oTmpAccount = oTmpGroupAccount.accounts[iAccountId]

          rows.push({
            title: oTmpAccount.label,
            amount_active: oTmpAccount.totals.amount_active * iMultiplier,
            amount_passive: oTmpAccount.totals.amount_passive * iMultiplier,
            amount_total: oTmpAccount.totals.amount_total * iMultiplier,
            percentage_passive: oTmpAccount.totals.percentage_passive,
            percentage_active: oTmpAccount.totals.percentage_active,
            row_type: 'account'
          })
        }

        rows.push({
          title: this.$t('common.subtotal') + ' (' + oTmpGroupAccount.label.toLowerCase() + ')',
          amount_active: oTmpGroupAccount.totals.amount_active * iMultiplier,
          amount_passive: oTmpGroupAccount.totals.amount_passive * iMultiplier,
          amount_total: oTmpGroupAccount.totals.amount_total * iMultiplier,
          percentage_passive: oTmpGroupAccount.totals.percentage_passive,
          percentage_active: oTmpGroupAccount.totals.percentage_active,
          row_type: 'subtotal'
        })
        rows.push({ title: '', row_type: 'empty' })
      }

      rows.push({
        title: this.$t('common.total'),
        amount_active: this.apiData[this.accountType].active.total_amounts.sum * iMultiplier,
        amount_passive: this.apiData[this.accountType].passive.total_amounts.sum * iMultiplier,
        amount_total: this.apiData[this.accountType].totals.total_amounts.sum * iMultiplier,
        percentage_passive: this.apiData[this.accountType].passive.total_amounts.percentage,
        percentage_active: this.apiData[this.accountType].active.total_amounts.percentage,
        row_type: 'total'
      })

      return rows
    }
  },
  watch: {
    apiData: {
      handler: function () {
        this.tableColumns = this.buildTableColumns()
        this.tableData = this.getTableDataRows()
      },
      deep: true,
      immediate: true
    },
    accountType: {
      handler: function () {
        this.tableColumns = this.buildTableColumns()
        this.tableData = this.getTableDataRows()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-performance-report-active-passive-account-table{
  .td-col-total, .td-col-percentage_active, {
    border-left:1px solid black;
  }

  .tr-subheading td{
    background: white;
    border-top:0;
    font-weight: bold;
  }

  .tr-empty td, .tr-subheading td{
    border-left:0;
  }
  th{
    border-left:0!important;
  }
}
</style>
