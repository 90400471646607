<template>
  <b-container fluid class="component-reports-performance-active-passive-account-bar-chart">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'result-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import SpirectaReportChartMixin from '@/views/reports/_inc/SpirectaReportChartMixin'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'ActivePassiveReportV2AccountBarChart',
  components: { BarChart },
  mixins: [chartOptions, SpirectaReportChartMixin, defaultChartColorPalettes],
  props: {
    isLoaded: { type: Boolean, default: false },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    resolution: { type: String, default: 'month' },
    chartType: { type: String, default: 'income-group-accounts' },
    apiData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.performance.active_passive_v2.table.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }

      // Prep
      const labels = []
      let sAccountType = 'income'
      const aActiveAmounts = []
      const aPassiveAmounts = []
      const _self = this
      let sBackgroundColorActive
      let sBackgroundColorPassive
      const aIncomeColors = this.getGreenTwoColors()
      const aExpenseColors = this.getRedTwoColors()
      let bCalculateAccountGroups = true

      // Define what kind of chart
      switch (this.chartType) {
        case 'income-group-accounts' :
        case 'income-accounts' :
          sAccountType = 'income'
          sBackgroundColorActive = aIncomeColors[0]
          sBackgroundColorPassive = aIncomeColors[1]
          break
        case 'expense-group-accounts' :
        case 'expense-accounts' :
          sAccountType = 'expense'
          sBackgroundColorActive = aExpenseColors[0]
          sBackgroundColorPassive = aExpenseColors[1]
          break
      }

      if (this.chartType === 'income-accounts' || this.chartType === 'expense-accounts') {
        bCalculateAccountGroups = false
      }

      const iMultiplier = sAccountType === 'income' ? 1 : -1

      // Options
      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        const suffix = (_self.chartType === 'income-expense-bar-percentage-chart') ? ' %' : ' ' + _self.currencySymbol
        return formatNumberToFull(value) + suffix
      }

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, data) {
        const suffix = (_self.chartType === 'income-expense-bar-percentage-chart') ? ' %' : ' ' + _self.currencySymbol
        return data.datasets[tooltipItem.datasetIndex].label + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      // Prepare data
      if (bCalculateAccountGroups) {
        // Account groups
        const aSortedData = [...this.apiData.account_groups[sAccountType]]
        aSortedData.sort((a, b) => a.totals.amount_total * iMultiplier < b.totals.amount_total * iMultiplier ? 1 : -1)

        for (const iGroupId in aSortedData) {
          const oTmpGroupAccount = aSortedData[iGroupId]
          if (oTmpGroupAccount.totals.amount_active !== 0 || oTmpGroupAccount.totals.amount_passive !== 0) {
            labels.push(oTmpGroupAccount.label)
            aActiveAmounts.push(oTmpGroupAccount.totals.amount_active * iMultiplier)
            aPassiveAmounts.push(oTmpGroupAccount.totals.amount_passive * iMultiplier)
          }
        }
      } else {
        // START ACCOUNT CALCULATION
        const aSortedData = [...this.apiData.account_groups[sAccountType]].map(function (aGroup) {
          return aGroup.accounts
        }).flat()
        aSortedData.sort((a, b) => a.totals.amount_total * iMultiplier < b.totals.amount_total * iMultiplier ? 1 : -1)

        let iCounter = 0
        const iAggregateBeyondXAccounts = 10
        const oAggregated = { label: this.$t('reports._common.aggregated'), amount_active: 0, amount_passive: 0, amount_total: 0 }

        for (const iAccountId in aSortedData) {
          const oTmpAccount = aSortedData[iAccountId]
          if (oTmpAccount.totals.amount_active !== 0 || oTmpAccount.totals.amount_passive !== 0) {
            if (iCounter++ <= iAggregateBeyondXAccounts) { // Aggregate when more than 10 accounts
              labels.push(this.splitForChartLabel(oTmpAccount.label))
              aActiveAmounts.push(oTmpAccount.totals.amount_active * iMultiplier)
              aPassiveAmounts.push(oTmpAccount.totals.amount_passive * iMultiplier)
            } else {
              oAggregated.amount_active += oTmpAccount.totals.amount_active * iMultiplier
              oAggregated.amount_passive += oTmpAccount.totals.amount_passive * iMultiplier
              oAggregated.amount_total = oAggregated.amount_active + oAggregated.amount_passive
            }
          }
        }

        if (oAggregated.amount_total > 0) {
          labels.push(this.splitForChartLabel(oAggregated.label))
          aActiveAmounts.push(oAggregated.amount_active)
          aPassiveAmounts.push(oAggregated.amount_passive)
        }
        // END ACCOUNT CALCULATION
      }

      this.chartData = {
        labels: labels,
        datasets: [
          { label: this.$t(this.translationPath + sAccountType + '_active'), data: aActiveAmounts, type: 'bar', backgroundColor: sBackgroundColorActive, borderWidth: { top: 2 }, borderColor: '#FFFFFF', stack: 'active' },
          { label: this.$t(this.translationPath + sAccountType + '_passive'), data: aPassiveAmounts, type: 'bar', backgroundColor: sBackgroundColorPassive, stack: 'active' }
        ]
      }
    }
  },
  watch: {
    apiData () {
      this.fillChart()
    },
    chartType () {
      this.fillChart()
    },
    options: {
      deep: true,
      handler () {
        this.fillChart()
      }
    }
  },
  created () {
    this.fillChart()
  }
}
</script>
