<template>
  <div class="component-performance-report-active-passive-table">
    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>

          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col cols="8">
                  <h2>{{ $t(translationPath + 'title_values') }}</h2>
                </b-col>
                <b-col>
                  <b-dropdown right
                              :text="$t(translationPath + 'toggle_chart') + ' | '+ $t('reports.performance.active_passive_v2.charts.'+ activeChart )"
                              variant='outline-secondary'
                              class="float-right text-regular toggleColumnWrapper"
                              size="sm"
                              v-if="isLoaded"
                  >
                    <b-dropdown-item @click="toggleChart('income-expense-bar-amount-chart')" v-bind:class="{ active: activeChart === 'income-expense-bar-amount-chart'}">{{$t('reports.performance.active_passive_v2.charts.income-expense-bar-amount-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('income-expense-bar-percentage-chart')" v-bind:class="{ active: activeChart === 'income-expense-bar-percentage-chart'}">{{$t('reports.performance.active_passive_v2.charts.income-expense-bar-percentage-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('income-area-percentage-chart')" v-bind:class="{ active: activeChart === 'income-area-percentage-chart'}">{{$t('reports.performance.active_passive_v2.charts.income-area-percentage-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('expense-area-percentage-chart')" v-bind:class="{ active: activeChart === 'expense-area-percentage-chart'}">{{$t('reports.performance.active_passive_v2.charts.expense-area-percentage-chart')}}</b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </template>
            <div class="col-lg-10 pl-0">
              <div class="description_table" v-html="$t(translationPath + 'description_total_table', { start_date: startDate, end_date: endDate })"></div>
            </div>

            <b-table
              v-if="isLoaded"
              class="spirecta-simple-table mb-0"
              show-empty
              hover
              responsive
              striped
              stacked="md"
              :items="this.getTableDataRows()"
              :fields="this.getTableDataColumns()"
              :busy="!isLoaded"
              :tbodyTrClass="trClass"
            >
              <template v-slot:table-busy>
                <loader/>
              </template>

              <!-- Period slots: BODY -->
              <template v-slot:[gomycell(sPeriod)]="row" v-for="sPeriod in this.apiData ? this.apiData.meta.periods : []">
                <template v-if="row.item.row_type === 'empty'">
                </template>
                <template v-else>
                  <span class="text-nowrap" v-bind:key="sPeriod">{{ amountFormatter(row.value) }}</span>
                </template>
              </template>

              <!--slot: total --->
              <template v-slot:cell(total)="row">
                <template v-if="row.item.row_type !== 'empty'">
                    <span class="text-nowrap">{{row.value}}</span>
                </template>
              </template>

              <!--slot: average --->
              <template v-slot:cell(average)="row">
                <template v-if="row.item.row_type !== 'empty'">
                    {{row.value}}
                </template>
              </template>

              <!-- END CUSTOM SLOTS -->
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SpirectaReportTableMixin from '@/views/reports/_inc/SpirectaReportTableMixin'
import Loader from '@/components/common/Loader'
import { mapState } from 'vuex'

export default {
  name: 'ActivePassiveReportV2TotalTable',
  components: { Loader },
  mixins: [SpirectaReportTableMixin],
  props: {
    isLoaded: { type: Boolean, default: false },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    resolution: { type: String, default: 'month' },
    activeChart: { type: String, default: 'income-expense-bar-amount-chart' },
    apiData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      translationPath: 'reports.performance.active_passive_v2.table.',
      tableData: [],
      tableColumns: []
    }
  },
  computed: {
    ...mapState('user', ['currentCOA'])
  },
  methods: {
    toggleChart (showChart) {
      this.showChart = showChart
      this.$emit('chart-to-display', showChart)
    },
    getTableDataRows () {
      if (!this.isLoaded) { return [] }

      switch (this.showChart) {
        case 'income-expense-bar-percentage-chart' :
        case 'income-area-percentage-chart' :
        case 'expense-area-percentage-chart' :
          return this.buildTablePercentageDataRows()
        case 'income-expense-bar-amount-chart' :
        default:
          return this.buildTableAmountDataRows()
      }
    },
    getTableDataColumns () {
      return this.buildTableColumns()
    },
    buildTableAmountDataRows () {
      if (!this.isLoaded) { return [] }
      const rows = []
      let sPeriod

      const oIncomeActive = { title: this.$t(this.translationPath + 'income_active'), row_type: 'data', class: 'text-right' }
      const oIncomePassive = { title: this.$t(this.translationPath + 'income_passive'), row_type: 'data', class: 'text-right' }
      const oIncomeTotal = { title: this.$t(this.translationPath + 'income_total'), row_type: 'subtotal', class: 'text-right' }

      const oExpenseActive = { title: this.$t(this.translationPath + 'expense_active'), row_type: 'data', class: 'text-right' }
      const oExpensePassive = { title: this.$t(this.translationPath + 'expense_passive'), row_type: 'data', class: 'text-right' }
      const oExpenseTotal = { title: this.$t(this.translationPath + 'expense_total'), row_type: 'subtotal', class: 'text-right' }

      const oResult = { title: this.$t('common.result'), row_type: 'total', class: 'tr-total' }

      for (const index in this.apiData.meta.periods) {
        sPeriod = this.apiData.meta.periods[index]
        oIncomeActive[sPeriod] = this.apiData.income.active.period_amounts[sPeriod]
        oIncomePassive[sPeriod] = this.apiData.income.passive.period_amounts[sPeriod]
        oIncomeTotal[sPeriod] = this.apiData.income.totals.period_amounts[sPeriod]

        oExpenseActive[sPeriod] = this.apiData.expense.active.period_amounts[sPeriod]
        oExpensePassive[sPeriod] = this.apiData.expense.passive.period_amounts[sPeriod]
        oExpenseTotal[sPeriod] = this.apiData.expense.totals.period_amounts[sPeriod]

        oResult[sPeriod] = this.apiData.totals.period_amounts[sPeriod]
      }

      oIncomeActive.total = this.apiData.income.active.total_amounts.sum
      oIncomePassive.total = this.apiData.income.passive.total_amounts.sum
      oIncomeTotal.total = this.apiData.income.totals.total_amounts.sum

      oExpenseActive.total = this.apiData.expense.active.total_amounts.sum
      oExpensePassive.total = this.apiData.expense.passive.total_amounts.sum
      oExpenseTotal.total = this.apiData.expense.totals.total_amounts.sum

      oIncomeActive.average = this.apiData.income.active.total_amounts.avg
      oIncomePassive.average = this.apiData.income.passive.total_amounts.avg
      oIncomeTotal.average = this.apiData.income.totals.total_amounts.avg

      oExpenseActive.average = this.apiData.expense.active.total_amounts.avg
      oExpensePassive.average = this.apiData.expense.passive.total_amounts.avg
      oExpenseTotal.average = this.apiData.expense.totals.total_amounts.avg

      oResult.total = this.apiData.totals.total_amounts.sum
      oResult.average = this.apiData.totals.total_amounts.avg

      rows.push(oIncomeActive)
      rows.push(oIncomePassive)
      rows.push(oIncomeTotal)
      rows.push({ row_type: 'empty' })
      rows.push(oExpenseActive)
      rows.push(oExpensePassive)
      rows.push(oExpenseTotal)
      rows.push({ row_type: 'empty' })
      rows.push(oResult)

      return rows
    },
    buildTablePercentageDataRows () {
      if (!this.isLoaded) { return [] }
      const rows = []
      let sPeriod

      const oIncomeActive = { title: this.$t(this.translationPath + 'income_active'), row_type: 'data', class: 'text-right' }
      const oIncomePassive = { title: this.$t(this.translationPath + 'income_passive'), row_type: 'data', class: 'text-right' }
      const oIncomeTotal = { title: this.$t(this.translationPath + 'income_total'), row_type: 'subtotal', class: 'text-right' }

      const oExpenseActive = { title: this.$t(this.translationPath + 'expense_active'), row_type: 'data', class: 'text-right' }
      const oExpensePassive = { title: this.$t(this.translationPath + 'expense_passive'), row_type: 'data', class: 'text-right' }
      const oExpenseTotal = { title: this.$t(this.translationPath + 'expense_total'), row_type: 'subtotal', class: 'text-right' }

      const oResult = { title: this.$t('common.result'), row_type: 'total', class: 'tr-total' }

      for (const index in this.apiData.meta.periods) {
        sPeriod = this.apiData.meta.periods[index]

        oIncomeActive[sPeriod] = this.apiData.income.active.period_percentages[sPeriod]
        oIncomePassive[sPeriod] = this.apiData.income.passive.period_percentages[sPeriod]
        oIncomeTotal[sPeriod] = this.apiData.income.active.period_percentages[sPeriod] + this.apiData.income.passive.period_percentages[sPeriod]

        oExpenseActive[sPeriod] = this.apiData.expense.active.period_percentages[sPeriod]
        oExpensePassive[sPeriod] = this.apiData.expense.passive.period_percentages[sPeriod]
        oExpenseTotal[sPeriod] = this.apiData.expense.active.period_percentages[sPeriod] + this.apiData.expense.passive.period_percentages[sPeriod]

        oResult[sPeriod] = this.apiData.totals.period_percentages[sPeriod]
      }

      oIncomeActive.average = this.apiData.income.active.total_percentages.avg
      oIncomePassive.average = this.apiData.income.passive.total_percentages.avg
      oIncomeTotal.average = this.apiData.income.active.total_percentages.avg + this.apiData.income.passive.total_percentages.avg

      oExpenseActive.average = this.apiData.expense.active.total_percentages.avg
      oExpensePassive.average = this.apiData.expense.passive.total_percentages.avg
      oExpenseTotal.average = this.apiData.expense.active.total_percentages.avg + this.apiData.expense.passive.total_percentages.avg

      // oResult.average = this.apiData.totals.total_amounts.avg

      rows.push(oIncomeActive)
      rows.push(oIncomePassive)
      rows.push(oIncomeTotal)
      rows.push({ row_type: 'empty' })
      rows.push(oExpenseActive)
      rows.push(oExpensePassive)
      rows.push(oExpenseTotal)

      return rows
    },
    buildTableColumns () {
      if (!this.isLoaded) { return [] }

      const aColumns = [{ key: 'title', label: '', sortable: false, class: 'text-left' }]
      let oFormatterFunction = null
      let bShowTotal = true
      let sClass = ''

      switch (this.showChart) {
        case 'income-expense-bar-percentage-chart' :
        case 'income-area-percentage-chart' :
        case 'expense-area-percentage-chart' :
          oFormatterFunction = this.percentageFormatter
          bShowTotal = false
          sClass = 'col-average'
          break
        case 'income-expense-bar-amount-chart' :
        default:
          oFormatterFunction = this.amountFormatter
      }

      for (const sPeriod in this.apiData.meta.periods) {
        aColumns.push({ key: '' + this.apiData.meta.periods[sPeriod], label: this.translatePeriodColumnTitleIntoNiceName(this.apiData.meta.periods[sPeriod], this.resolution).toString(), sortable: false, class: 'text-left text-md-right', formatter: oFormatterFunction })
      }

      if (bShowTotal) {
        aColumns.push({ key: 'total', label: this.$t('common.sum'), sortable: false, class: 'text-right col-total', formatter: oFormatterFunction })
      }

      aColumns.push({ key: 'average', label: this.$t('common.average'), sortable: false, class: 'text-right ' + sClass, formatter: oFormatterFunction })

      return aColumns
    }
  },
  watch: {
    apiData: {
      handler: function () {
        this.tableColumns = this.buildTableColumns()
        this.tableData = this.getTableDataRows()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-performance-report-active-passive-table{
  td.col-total, td.col-average{
    border-left: 1px solid black;
  }
  .description_table ul li{
    font-size: 14px;
    color: rgb(33, 37, 41);
    padding-bottom:10px;
    font-weight: 300;
  }
  .description_table ul li:last-child{padding-bottom: 0;}
  .description_table ul li b{font-weight: 600}
}
</style>
