<template>
  <b-container fluid class="reports-performance-active-passive-income-expense-bar-chart">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'result-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import SpirectaReportChartMixin from '@/views/reports/_inc/SpirectaReportChartMixin'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'ActivePassiveReportV2IncomeExpenseBarChart',
  components: { BarChart },
  mixins: [chartOptions, SpirectaReportChartMixin],
  props: {
    isLoaded: { type: Boolean, default: false },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    resolution: { type: String, default: 'month' },
    chartType: { type: String, default: 'income-expense-bar-percentage-chart' },
    apiData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.performance.active_passive_v2.table.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }

      // Prep
      const labels = []
      const aIncomeActiveData = []
      const aIncomePassiveData = []
      const aExpenseActiveData = []
      const aExpensePassiveData = []
      const aIncomeColors = this.getGreenTwoColors()
      const aExpenseColors = this.getRedTwoColors()
      let sPeriod = null
      const _self = this

      // Options
      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        const suffix = (_self.chartType === 'income-expense-bar-percentage-chart') ? ' %' : ' ' + _self.currencySymbol
        return formatNumberToFull(value) + suffix
      }

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, data) {
        const suffix = (_self.chartType === 'income-expense-bar-percentage-chart') ? ' %' : ' ' + _self.currencySymbol
        return data.datasets[tooltipItem.datasetIndex].label + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      for (const idx in this.apiData.meta.periods) {
        sPeriod = this.apiData.meta.periods[idx]

        labels.push(this.translatePeriodColumnTitleIntoNiceName(sPeriod, this.resolution))

        if (this.chartType === 'income-expense-bar-amount-chart') {
          aIncomeActiveData.push(this.apiData.income.active.period_amounts[sPeriod].toFixed(0))
          aExpenseActiveData.push((this.apiData.expense.active.period_amounts[sPeriod] * -1).toFixed(0))

          aIncomePassiveData.push(this.apiData.income.passive.period_amounts[sPeriod].toFixed(0))
          aExpensePassiveData.push((this.apiData.expense.passive.period_amounts[sPeriod] * -1).toFixed(0))
        } else if (this.chartType === 'income-expense-bar-percentage-chart') {
          aIncomeActiveData.push(this.apiData.income.active.period_percentages[sPeriod].toFixed(0))
          aExpenseActiveData.push((this.apiData.expense.active.period_percentages[sPeriod]).toFixed(0))

          aIncomePassiveData.push(this.apiData.income.passive.period_percentages[sPeriod].toFixed(0))
          aExpensePassiveData.push((this.apiData.expense.passive.period_percentages[sPeriod]).toFixed(0))
        }
      }

      this.chartData = {
        labels: labels,
        datasets: [
          { label: this.$t(this.translationPath + 'income_active'), data: aIncomeActiveData, type: 'bar', backgroundColor: aIncomeColors[0], borderWidth: { top: 2 }, borderColor: '#FFFFFF', stack: 'income' },
          { label: this.$t(this.translationPath + 'income_passive'), data: aIncomePassiveData, type: 'bar', backgroundColor: aIncomeColors[1], stack: 'income' },
          { label: this.$t(this.translationPath + 'expense_active'), data: aExpenseActiveData, type: 'bar', backgroundColor: aExpenseColors[0], borderWidth: { top: 2 }, borderColor: '#FFFFFF', stack: 'expense' },
          { label: this.$t(this.translationPath + 'expense_passive'), data: aExpensePassiveData, type: 'bar', backgroundColor: aExpenseColors[1], stack: 'expense' }
        ]
      }
    }
  },
  watch: {
    apiData () {
      this.fillChart()
    },
    chartType () {
      this.fillChart()
    },
    options: {
      deep: true,
      handler () {
        this.fillChart()
      }
    }
  },
  created () {
    this.fillChart()
  }
}
</script>
