<template>
  <b-container fluid class="component-reports-performance-active-passive-account-pie-chart">
    <pie-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'result-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </pie-chart>
  </b-container>
</template>

<script>
import PieChart from '@/components/chartjs/DoughnutChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import chartPalettes from '@/mixins/defaultChartColorPalettes'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'
import SpirectaReportChartMixin from '@/views/reports/_inc/SpirectaReportChartMixin'

export default {
  name: 'ActivePassiveReportV2AccountPieChart',
  components: { PieChart },
  mixins: [chartOptions, chartPalettes, SpirectaReportChartMixin],
  props: {
    isLoaded: { type: Boolean, default: false },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    resolution: { type: String, default: 'month' },
    chartType: { type: String, default: 'income-group-accounts' },
    apiData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.performance.active_passive_v2.table.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }

      const aLabels = []
      const aColors = []
      const aAmountsData = []
      // const oPalette = this.getChartPalette()
      let sAccountType = 'income'
      let sBackgroundColorActive = ''
      let sBackgroundColorPassive = ''
      let iMultiplier = 1
      const aIncomeColors = this.getGreenTwoColors()
      const aExpenseColors = this.getRedTwoColors()
      const currencySymbol = this.currencySymbol ? this.currencySymbol : 'kr'

      switch (this.chartType) {
        case 'income-group-accounts-pie-chart' :
          sAccountType = 'income'
          sBackgroundColorActive = aIncomeColors[0]
          sBackgroundColorPassive = aIncomeColors[1]
          break
        case 'expense-group-accounts-pie-chart' :
          sAccountType = 'expense'
          sBackgroundColorActive = aExpenseColors[0]
          sBackgroundColorPassive = aExpenseColors[1]
          iMultiplier = -1
          break
      }

      /* Modify the Y-Axis */
      this.chartOptions.scales.yAxes[0].display = false
      this.chartOptions.scales.xAxes[0].display = false
      this.chartOptions.legend.display = true

      /* Make half circle */
      this.chartOptions.rotation = -Math.PI
      this.chartOptions.circumference = Math.PI

      /* Tooltip */
      /* Modify the tooltip onmoauseover */
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, chartData) {
        return chartData.labels[tooltipItem.index] + ': ' + formatNumberToFull(chartData.datasets[0].data[tooltipItem.index]) + ' ' + currencySymbol
      }

      /* Prepare data */
      // Productive
      const aSortedData = [...this.apiData.account_groups[sAccountType]]
      aSortedData.sort((a, b) => a.totals.amount_total * iMultiplier < b.totals.amount_total * iMultiplier ? 1 : -1)

      for (const iGroupId in aSortedData) {
        const oTmpGroupAccount = aSortedData[iGroupId]
        if (oTmpGroupAccount.totals.amount_active !== 0 || oTmpGroupAccount.totals.amount_passive !== 0) {
          aLabels.push(this.splitForChartLabel(oTmpGroupAccount.label) + ' (' + this.$t('common.active').toLowerCase() + ')')
          aAmountsData.push(oTmpGroupAccount.totals.amount_active * iMultiplier)
          aColors.push(sBackgroundColorActive)
        }
      }
      for (const iGroupId in aSortedData) {
        const oTmpGroupAccount = aSortedData[iGroupId]
        if (oTmpGroupAccount.totals.amount_active !== 0 || oTmpGroupAccount.totals.amount_passive !== 0) {
          aLabels.push(this.splitForChartLabel(oTmpGroupAccount.label) + ' (' + this.$t('common.passive').toLowerCase() + ')')
          aAmountsData.push(oTmpGroupAccount.totals.amount_passive * iMultiplier)
          aColors.push(sBackgroundColorPassive)
        }
      }

      this.chartData = {
        labels: aLabels,
        datasets: [{
          label: this.$t(this.translationPath + 'period'),
          data: aAmountsData,
          backgroundColor: aColors
        }]
      }
    }
  },
  watch: {
    apiData () {
      this.fillChart()
    },
    isLoaded () {
      this.fillChart()
    }
  },
  created () {
    this.fillChart()
  }
}
</script>
