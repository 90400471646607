<template>
  <div class="component-performance-report-active-passive-transaction-table">
    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>

          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col cols="8">
                  <h2>{{ $t(translationPath + 'title_transactions', { start_date: startDate, end_date: endDate }) }}</h2>
                </b-col>
                <b-col>

                </b-col>
              </b-row>
            </template>
            <div class="col-lg-8 pl-0">
              <div v-html="$t(translationPath + 'description_transactions', { start_date: startDate, end_date: endDate })"></div>
            </div>

            <b-table
              v-if="isLoaded"
              class="spirecta-simple-table mb-0"
              show-empty
              hover
              responsive
              striped
              stacked="md"
              :items="this.getTableDataRows()"
              :fields="this.getTableDataColumns()"
              :filter="sTableFilter"
              :filter-included-fields="['title', 'offset_account']"
              :busy="!isLoaded"
              :tbodyTrClass="trClass"
              :per-page="iPaginationPerPage"
              :current-page="iPaginationCurrentPage"
            >
              <template v-slot:table-busy>
                <loader/>
              </template>

              <!-- FILTER -->
              <template slot="top-row">
                <td :colspan="this.tableColumns.length">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-search text-secondary"></i>
                    <b-form-input v-model="sTableFilter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
                  </div>
                </td>
              </template>

              <!-- START CUSTOM SLOTS -->

              <!-- slot: date --->
              <template v-slot:cell(date)="row">
                <template>
                  <span class="text-nowrap">{{row.value}}</span>
                </template>
              </template>

              <!-- slot: title --->
              <template v-slot:cell(title)="row">
                <template>
                  <b-link :to="'/transactions/view/transaction/'+row.item.id">{{row.value}}</b-link>
                </template>
              </template>

              <!-- END CUSTOM SLOTS -->

            </b-table>

            <!-- PAGINATION -->
            <div class="pagination-wrapper" v-if="isLoaded">
              <div class="d-flex  flex-column flex-md-row justify-content-between">
                <div class="d-flex flex-column flex-md-row mb-3 mb-md-0">
                  <div v-if="iPaginationTotalRows > 0" class="d-flex align-items-center justify-content-center show-per-page-wrapper">
                    <span class="text-gray">{{ $t('common.pagination.showing')}} {{(iPaginationCurrentPage - 1) * iPaginationPerPage + 1}} {{ $t('common.pagination.to')}} {{ paginationToNumber() }} {{ $t('common.pagination.of')}} {{iPaginationTotalRows}}</span>
                  </div>

                </div>
                <div class="d-flex align-items-center justify-content-center pagination-pages-wrapper">
                  <b-pagination v-if="iPaginationTotalRows > 0" :total-rows="iPaginationTotalRows" :per-page="( iPaginationPerPage)" :prev-text="$t('common.pagination.prev')" :next-text="$t('common.pagination.next')" v-model="iPaginationCurrentPage" class="my-0"/>
                </div>
              </div>
            </div>
            <!-- END PAGINATION -->

          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SpirectaReportTableMixin from '@/views/reports/_inc/SpirectaReportTableMixin'
import Loader from '@/components/common/Loader'
import { mapState } from 'vuex'

export default {
  name: 'ActivePassiveReportV2AccountsTable',
  components: { Loader },
  mixins: [SpirectaReportTableMixin],
  props: {
    isLoaded: { type: Boolean, default: false },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    apiData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      translationPath: 'reports.performance.active_passive_v2.table.',
      tableData: [],
      tableColumns: [],
      showChart: 'income-group-accounts',
      sTableFilter: '',
      iPaginationCurrentPage: 1,
      iPaginationPerPage: 25,
      iPaginationTotalRows: 0
    }
  },
  computed: {
    ...mapState('user', ['currentCOA'])
  },
  methods: {
    getTableDataRows () {
      if (!this.isLoaded) { return [] }

      return this.buildTableAmountDataRows()
    },
    getTableDataColumns () {
      return this.buildTableColumns()
    },
    buildTableColumns () {
      if (!this.isLoaded) { return [] }

      const aColumns = []

      aColumns.push({ key: 'date', label: this.$t('common.date'), sortable: true, class: 'text-left td-date' })
      aColumns.push({ key: 'title', label: this.$t('common.title'), sortable: true, class: 'text-left td-title' })
      aColumns.push({ key: 'offset_account', label: this.$t('common.category'), sortable: true, class: 'text-left td-account' })
      aColumns.push({ key: 'amount_active', label: this.$t(this.translationPath + 'amount_active'), sortable: true, class: 'text-right ', formatter: this.amountFormatter })
      aColumns.push({ key: 'amount_passive', label: this.$t(this.translationPath + 'amount_passive'), sortable: true, class: 'text-right ', formatter: this.amountFormatter })
      aColumns.push({ key: 'percentage_active', label: this.$t(this.translationPath + 'percentage_active'), sortable: true, class: 'text-right td-col-percentage_active', formatter: this.percentageFormatter })
      aColumns.push({ key: 'percentage_passive', label: this.$t(this.translationPath + 'percentage_passive'), sortable: true, class: 'text-right', formatter: this.percentageFormatter })
      aColumns.push({ key: 'amount_total', label: this.$t('common.total'), sortable: true, class: 'text-right td-col-total', formatter: this.amountFormatter })

      return aColumns
    },
    buildTableAmountDataRows () {
      if (!this.isLoaded) { return [] }
      const rows = []

      for (const iTransactionId in this.apiData.transactions) {
        const oTransaction = this.apiData.transactions[iTransactionId]

        rows.push({
          id: oTransaction.id,
          date: oTransaction.date,
          title: oTransaction.title,
          offset_account: oTransaction.offset_account,
          amount_active: oTransaction.amount_active,
          amount_passive: oTransaction.amount_passive,
          amount_total: oTransaction.amount_total,
          percentage_passive: oTransaction.percentage_passive,
          percentage_active: oTransaction.percentage_active,
          row_type: 'transaction'
        })
      }

      this.iPaginationTotalRows = this.apiData.meta.transaction_count + 1

      return rows
    },
    // Calculate X for label "showing 1 to X"
    paginationToNumber () {
      let iToNumber = this.iPaginationCurrentPage * this.iPaginationPerPage
      if (iToNumber > this.iPaginationTotalRows) {
        iToNumber = this.iPaginationTotalRows
      }
      return iToNumber
    }
  },
  watch: {
    apiData: {
      handler: function () {
        this.tableColumns = this.buildTableColumns()
        this.tableData = this.getTableDataRows()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-performance-report-active-passive-transaction-table{
  .td-col-total, .td-col-percentage_active, {
    border-left:1px solid black;
  }
  .pagination-wrapper{border-top:1px solid black;padding-top:10px;}
}
</style>
